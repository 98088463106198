import { Fragment, memo, ReactNode, useMemo } from "react"

import { css, Theme, useTheme } from "@emotion/react"
import { IconName } from "@fortawesome/fontawesome-common-types"
import { transparentize } from "polished"

import { AnyNcsDeviceStatus, GenericDeviceStatus, getGenericDeviceStatus } from "@ncs/ncs-api"
import { arrayWrap, noNullish } from "@ncs/ts-utils"

import { Box, BoxProps } from "../layout"
import { Heading, HeadingProps, IconFamily, Paragraph } from "../typography"

export interface CardProps extends BoxProps {
	variant?: "plain" | "droplet"
	backgroundColorCategory?: AnyNcsDeviceStatus | null
	heading?: string
	headingIcon?: IconName
	headingIconFamily?: IconFamily
	headingIsLoading?: boolean
	headingDetail?: string | ReactNode | (string | ReactNode | null | undefined)[]
	headingVariant?: HeadingProps["variant"]
	headingBottomMargin?: string | number
	renderRight?: () => ReactNode
	topDetail?: ReactNode
}

export const Card: React.FC<CardProps> = memo(
	({
		variant = "plain",
		backgroundColorCategory = GenericDeviceStatus.Normal,
		heading,
		headingIcon,
		headingIconFamily,
		headingIsLoading,
		headingDetail,
		headingVariant = "h1",
		headingBottomMargin = 3,
		renderRight,
		topDetail,
		onClick,
		children,
		textAlign,
		...rest
	}) => {
		const theme = useTheme()

		const headingDetails = useMemo(() => {
			return noNullish(arrayWrap(headingDetail))
		}, [headingDetail])

		const variantStyle = useMemo(() => {
			switch (variant) {
				case "plain": {
					return css`
						background: #ffffff;
						box-shadow: 0 0 0.375rem 0 #d1d5db;
						border-radius: 0.5rem;
						text-align: ${textAlign};
					`
				}
				case "droplet": {
					return css`
						box-shadow: 0 0 20px ${transparentize(0.85, theme.palette.primary.main)};
						border-radius: 0 25px 0 25px;
					`
				}
			}
		}, [variant, theme])

		const backgroundStyle = useMemo(() => {
			switch (getGenericDeviceStatus(backgroundColorCategory)) {
				case GenericDeviceStatus.Warning: {
					return css`
						background-color: ${transparentize(0.95, theme.palette.warning.main)};
						&:hover {
							background-color: ${onClick ?
								transparentize(0.75, theme.palette.warning.main)
							:	undefined};
						}
					`
				}
				case GenericDeviceStatus.Error: {
					return css`
						background-color: ${transparentize(0.95, theme.palette.error.main)};
						&:hover {
							background-color: ${onClick ?
								transparentize(0.85, theme.palette.error.main)
							:	undefined};
						}
					`
				}
				default: {
					return css`
						background-color: white;
						&:hover {
							background-color: ${onClick ? "#fafafa" : undefined};
						}
					`
				}
			}
		}, [backgroundColorCategory, theme, onClick])

		const showCardHeading = !!heading || !!headingDetails.length || headingIsLoading
		const showTopOfCard = showCardHeading || !!renderRight

		return (
			<Box
				maxWidth={theme.layout?.contentOuterMaxWidth ?? 110}
				mx="auto"
				py={1.5}
				px={CARD_X_PADDING_MD_UP}
				{...rest}
				smProps={{
					...rest.smProps,
					px: rest.px ?? CARD_X_PADDING_SM_DOWN,
				}}
				onClick={onClick}
				css={[variantStyle, backgroundStyle, baseStyle]}
			>
				{!!topDetail &&
					(typeof topDetail === "string" ?
						<Paragraph small secondary mb={0.5} mt={-0.5}>
							{topDetail}
						</Paragraph>
					:	{ topDetail })}

				{showTopOfCard && (
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="flex-start"
						flexWrap="wrap"
						rowGap={1}
						mb={headingBottomMargin}
					>
						{showCardHeading && (
							<Box>
								{!!heading && (
									<Heading
										variant={headingVariant}
										mb={0.35}
										icon={headingIcon}
										iconFamily={headingIconFamily}
										isLoading={headingIsLoading}
									>
										{heading}
									</Heading>
								)}
								{!!headingDetails.length && (
									<Box display="flex" alignItems="center">
										{headingDetails.map((detail, i) => {
											return (
												<Fragment key={String(detail)}>
													{i !== 0 && (
														<span css={spacingPipeStyle}>|</span>
													)}
													{typeof detail === "string" ?
														<Paragraph small color="secondary">
															{detail}
														</Paragraph>
													:	detail}
												</Fragment>
											)
										})}
									</Box>
								)}
							</Box>
						)}
						{!!renderRight && renderRight()}
					</Box>
				)}
				{children}
			</Box>
		)
	}
)

export const CARD_X_PADDING_MD_UP = 1.5
export const CARD_X_PADDING_SM_DOWN = 0.75

const baseStyle = (theme: Theme) => css`
	border: 2px solid transparent;
	&:focus {
		outline: none;
		border: 2px solid #eee;
	}
`
const spacingPipeStyle = css`
	margin: 0 0.85rem;
`
